import * as React from "react"
import {Helmet} from "react-helmet"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {Link, navigate} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import CookieConsent from "react-cookie-consent";
//service_1yepwtk
// markup
const IndexPage = () => {
  return (
    <main className="wrapper">
      <Helmet
        htmlAttributes={{
          lang: 'de',
        }}>
        <title>Mobility Leasing - Home</title>
        <meta charSet="utf-8"/>
        <meta name="description"
              content="Auto Leasing, einfach und schnell bei Mobility Leasing"/>
        <body style={{margin: 0}}/>
        {/* adding font-awesome icons */}
        <script src={"https://kit.fontawesome.com/02130b3d51.js"} crossOrigin="anonymous"/>

      </Helmet>

      <NavBar current="home"/>

      <div className="mx-auto lg:text-left lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <div className="flex flex-wrap lg:flex-nowrap justify-center flex-col md:flex-row gap-4">
          <div className="cards">
            <h2 className="text-xl">Leasingarten</h2>
            <p>
              Wir finden für Sie die geeignete Leasingart, und lassen Sie auch nach Vertrags- abschluss nicht alleine.
            </p>
          </div>
          <div className="cards">
            <h2 className="text-xl">Wichtige Faktoren</h2>
            <p>Wir lassen Sie mit den wichtigen Entscheidungen nicht allein.</p>
          </div>
          <div className="cards">
            <h2 className="text-xl">Laufzeiten</h2>
            <p>
              Flexible Laufzeiten sind in der heutigen Wirtschaft ein muss.
            </p>
          </div>
          <div className="cards">
            <h2 className="text-xl">Kostenkontrolle</h2>
            <p>
              Aufs Geradewohl zu wirtschaften kann sich heute kein Unternehmen <Link to="/service"
                                                                                     className="text-blue-500 underline">mehr
              ..</Link>
            </p>
          </div>
        </div>

        <div className="flex flex-wrap text-center lg:flex-nowrap mt-8 justify-center flex-col md:flex-row gap-4">
          <div className="md:w-full lg:w-1/3 cards">
            <h2 className="text-xl">Unser Service</h2>
            <ul className="px-4">
              <li className="mt-4">
                Die Nutzung des Fahrzeuges, nicht das Eigentum steht im Mittelpunkt. Dadurch bezahlen Sie beim Leasing
                nur den Wertverlust während der Laufzeit und nicht die gesamten Anschaffungskosten!.
              </li>
              <li className="mt-4">Die monatliche Zahlung wird individuell auf Ihre finanziellen Möglichkeiten
                abgestimmt. Sie selbst bestimmen die Höhe der Leasingrate durch die Laufzeit, die Kilometer, die Sie
                jedes Jahr mit Ihrem Auto zurück legen oder durch eine Vorauszahlung.
              </li>
              <li className="mt-4">Wir kümmern uns um die komplette Abwicklung.</li>
            </ul>
            <Link to="/service" className="text-blue-500 underline">Mehr ..</Link>
          </div>
          <div className="md:w-1/3 md:mt-8 lg:mt-0 mx-auto lg:mx-0 bg-white shadow-2xl rounded-b-3xl">
            <div>
              <div className="rounded-t-3xl shadow-2xl bg-gray-100">
                <StaticImage src="../images/erfahrung.png" alt="Unsere Erfahrung" className="w-full" imgClassName="img-contain h-auto w-full shadow-2xl rounded-t-3xl"/>
              </div>
              <div>
                <h2 className="text-center text-gray-800 bg-white text-2xl font-bold pt-6">Unsere Erfahrung</h2>

                <div className="w-5/6 m-auto">
                  <p className="text-center text-gray-500 pt-5">Unsere jahrzehntelange Erfahrung in der Kfz
                    Finanzierung, Sind ein Garant dafür dass sie wirtschaftlich optimal, schnell und zuverlässig an Ihr
                    Wunschfahrzeug kommen.
                    <br/><br/>
                    Es gibt nicht so gut wie eine Automarke, für die wir nicht schon einen Leasingvertrag abgeschlossen
                    hätten.
                  </p>
                  <br/>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 md:mt-8 lg:mt-0 mx-auto lg:mx-0 shadow-2xl rounded-b-3xl">
            <div>
              <div className="rounded-t-3xl shadow-2xl bg-gray-100">
                <StaticImage src="../images/qualität.png" alt="Unsere Qualität" imgClassName="h-auto"/>
              </div>
              <div>
                <h2 className="text-center text-gray-800 text-2xl font-bold pt-6">Unsere Qualität</h2>

                <div className="w-5/6 m-auto">
                  <p className="text-center text-gray-500 pt-5">
                    Wir verschaffen Ihnen finanzielle Beweglichkeit, damit sie richtig in Fahrt kommen. Genießen Sie
                    Auto-Leasing schon bevor Sie in Ihr Auto einsteigen.
                  </p>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Verstanden"
        declineButtonText="Ablehnen"
        cookieName="Cookie Consent"
        style={{background: "#1F2937"}}
        buttonStyle={{background: "#FB923C", color: "white"}}
        enableDeclineButton={true}
        flipButtons={true}
        onDecline={() => {
          navigate("/datenschutz")
        }}
      >
        Diese Seite benützt Cookies für eine optimale Erfahrung auf dieser Website.
      </CookieConsent>
      <Footer/>

    </main>
  )
}

export default IndexPage
